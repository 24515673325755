import { parse } from 'papaparse';
import ReactGA from 'react-ga4';

const API_KEY = process.env.REACT_APP_TFL_API_KEY;

let stationData = new Map();
let distanceData = new Map();
let apiCache = new Map();

export const setStationData = (data) => {
  stationData = data;
};
export const setDistanceData = (data) => {
  distanceData = data;
};

export const getStationData = () => stationData;
export const getDistanceData = () => distanceData;

export const loadAllStationsData = async () => {
  try {
    const response = await fetch('/all_stations.csv');
    const text = await response.text();
    const { data } = parse(text, { header: true });
    data.forEach((row, index) => {
      if (!row.Line) {
        console.warn(`Warning: 'Line' data missing for row ${index}, Station: ${row['Station Name']}`);
      }

      const name = row['Station Name'];
      if (!stationData.has(name)) {
        stationData.set(name, []);
      }
      stationData.get(name).push({
        name: name,
        lat: parseFloat(row.Latitude),
        lon: parseFloat(row.Longitude),
        lines: row.Line ? JSON.parse(row.Line.replace(/'/g, '"')) : []
      });
    });
    console.log('===> All stations data loaded. Total stations:', stationData.size);
  } catch (error) {
    console.error('===> Failed to load all_stations.csv:', error);
  }
};

export const loadDistanceData = async () => {
  const files = ['all_durations_zone_1.csv', 'all_durations_zone_2.csv'];
  
  const processRow = (row) => {
    const fromStation = row.from_station;
    const toStation = row.to_station;
    const duration = parseFloat(row.duration);

    if (!distanceData.has(fromStation)) {
      distanceData.set(fromStation, new Map());
    }
    distanceData.get(fromStation).set(toStation, duration);
  };

  for (const file of files) {
    try {
      const response = await fetch(`/${file}`);
      const text = await response.text();
      const { data } = parse(text, { header: true });
      data.forEach(processRow);
      console.log(`Loaded distance data from ${file}`);
    } catch (error) {
      console.error(`Failed to load ${file}:`, error);
    }
  }
  console.log('===> Distance data loaded. Total from stations:', distanceData.size);
};

export const matchStationName = (input) => {
  const normalizedInput = input.toLowerCase().replace(/(underground|dlr|overground)( station)?$/, '').trim();

  for (const [stationName, stations] of stationData.entries()) {
    const normalizedStationName = stationName.toLowerCase().replace(/(underground|dlr|overground)( station)?$/, '').trim();
    if (normalizedStationName.includes(normalizedInput) || normalizedInput.includes(normalizedStationName)) {
      return stations[0]; // Return the first one if multiple matches
    }
  }

  console.log(`No match found for station: ${input}`);
  return null;
};

export const createBoundary = (locations) => {
  const lats = locations.map(loc => loc.lat);
  const lons = locations.map(loc => loc.lon);
  const minLat = Math.min(...lats);
  const maxLat = Math.max(...lats);
  const minLon = Math.min(...lons);
  const maxLon = Math.max(...lons);

  // Add padding (0.01 degrees is roughly 1km)
  return {
    minLat: minLat - 0.01,
    maxLat: maxLat + 0.01,
    minLon: minLon - 0.01,
    maxLon: maxLon + 0.01
  };
};

export const getStationsWithinBoundary = (boundary) => {
  const stations = Array.from(stationData.values())
    .flat()
    .filter(station => 
      station.lat >= boundary.minLat && station.lat <= boundary.maxLat &&
      station.lon >= boundary.minLon && station.lon <= boundary.maxLon
    );
  console.log(`Found ${stations.length} stations within the boundary`);
  return stations;
};

export const calculateTravelTime = async (from, to) => {
  // Check pre-calculated distances
  if (distanceData.has(from.name) && distanceData.get(from.name).has(to.name)) {
    // console.log(`✅ Using pre-calculated distance for ${from.name} to ${to.name}`);
    console.log(`✅ Using pre-calculated distance`);
    return distanceData.get(from.name).get(to.name);
  }

  // Check cache
  const cacheKey = `${from.lat},${from.lon}-${to.lat},${to.lon}`;
  if (apiCache.has(cacheKey)) {
    // console.log(`⏳ Using cached journey time for ${from.name} to ${to.name}`);
    console.log(`⏳ Using cached journey time`);
    return apiCache.get(cacheKey);
  }

  // If not in our data or cache, call TfL API
  console.log(`😩 Calling TfL API for journey time from ${from.name} to ${to.name}`);
  const url = `https://api.tfl.gov.uk/Journey/JourneyResults/${from.lat},${from.lon}/to/${to.lat},${to.lon}?app_key=${API_KEY}`;
  
  try {
    const response = await fetch(url);
    const data = await response.json();
    if (data.journeys && data.journeys.length > 0) {
      const duration = data.journeys[0].duration;
      apiCache.set(cacheKey, duration);
      return duration;
    }
  } catch (error) {
    if (error.name === 'AbortError') {
      console.log('===> Fetch aborted');
      throw error;
    }
    console.error('Error fetching journey time:', error);
  }

  return Infinity;
};

export const findOptimalMeetingPoint = async (locations, preference) => {
  console.log('===> findOptimalMeetingPoint called with locations:', locations, 'and preference:', preference);
  try {
    await Promise.all([loadAllStationsData(), loadDistanceData()]);  // Ensure data is loaded

    // Match input locations to actual stations
    const matchedLocations = locations.map(loc => {
      const matchedStation = matchStationName(loc.name);
      return matchedStation || loc;  // If no match found, use the original location
    });

    console.log('===> Matched locations:', matchedLocations.map(loc => loc.name));

    let optimalPoint;
    if (preference === 'location') {
      optimalPoint = calculateGeographicMidpoint(matchedLocations);
    } else {
      optimalPoint = await calculateTimeMidpoint(matchedLocations);
    }

    console.log('===> Optimal point before finding nearest station:', optimalPoint);

    const nearestStation = await findNearestStation(optimalPoint);
    
    console.log('===> Final optimal meeting point:', nearestStation);

    return { ...nearestStation, isGeographicMidpoint: preference === 'location' };
  } catch (error) {
    if (error.name === 'AbortError') {
      console.log('===> 🛑 USER ABORTED SEARCH');
      throw error;
    }
    console.error('Error in findOptimalMeetingPoint:', error);
    throw error;
  }
};

export const calculateTimeMidpoint = async (locations) => {
  console.log(`Calculating time midpoint for: ${locations.map(loc => loc.name).join(', ')}`);
  
  let bestPoint = locations[0];
  let minTotalTime = Infinity;

  const boundary = createBoundary(locations);
  const candidateStations = getStationsWithinBoundary(boundary);

  for (const station of candidateStations) {
    let totalTime = 0;
    for (const location of locations) {
      const time = await calculateTravelTime(location, station);
      totalTime += time;
    }
    // console.log(`Total time for ${station.name}: ${totalTime}`); // todo: uncomment this
    if (totalTime < minTotalTime) {
      minTotalTime = totalTime;
      bestPoint = station;
    }
  }

  console.log(`Best time midpoint: ${bestPoint.name} with total time ${minTotalTime}`);
  return bestPoint;
};

export const calculateGeographicMidpoint = (locations) => {
  const sumLat = locations.reduce((sum, loc) => sum + loc.lat, 0);
  const sumLon = locations.reduce((sum, loc) => sum + loc.lon, 0);
  const midLat = sumLat / locations.length;
  const midLon = sumLon / locations.length;

  console.log(`Geographic midpoint: ${midLat}, ${midLon}`);
  return { lat: midLat, lon: midLon };
};

export const findNearestStation = async (point) => {
  console.log(`Finding nearest station for: ${point.name || 'Unknown'} at ${point.lat}, ${point.lon}`);
  const url = `https://api.tfl.gov.uk/StopPoint?lat=${point.lat}&lon=${point.lon}&stopTypes=NaptanMetroStation,NaptanRailStation&radius=1000&app_key=${API_KEY}`;

  try {
    const response = await fetch(url);
    const data = await response.json();
    if (data.stopPoints && data.stopPoints.length > 0) {
      console.log(`Nearest station found: ${data.stopPoints[0].commonName}`);
      return {
        name: data.stopPoints[0].commonName,
        lat: data.stopPoints[0].lat,
        lon: data.stopPoints[0].lon
      };
    }
    console.log(`No nearest station found for: ${point.name || 'Unknown'}`);
    return { name: "Nearest Point", lat: point.lat, lon: point.lon };
  } catch (error) {
    if (error.name === 'AbortError') {
      console.log('Fetch aborted');
    }
    console.error('Error finding nearest station:', error);
    return { name: "Nearest Point", lat: point.lat, lon: point.lon };
  }
};

export const findNearbyPlaces = async (lat, lon, placeType) => {
    console.log(`===> findNearbyPlaces started with lat: ${lat}, lon: ${lon}, type: ${placeType}`);
    const startTime = performance.now();
    const places = await fetchPlaces(lat, lon, placeType);
    const endTime = performance.now();
    
    if (places.length === 0) {
        console.log('===> ERROR: No places found');
    } else {
        console.log('===> Nearby places:', places.slice(0, 5));
    }

    ReactGA.event({
      category: 'result',
      action: 'find_nearby_places',
      label: placeType,
      value: Math.round(endTime - startTime)
  });

    return places;
};

export const fetchPlaces = async (lat, lon, placeType) => {
  try {
    const response = await fetch('fhrs_data_2024-08-05.csv');
    const csvData = await response.text();
    
    let places = [];
    const lines = csvData.split('\n');
    const headers = lines[0].split(',');
    
    for (let i = 1; i < lines.length; i++) {
      const values = lines[i].split(',');
      const type = values[headers.indexOf('function')];
      
      if ((placeType === 'pub' && type === 'Pub/bar/nightclub') ||
          (placeType === 'restaurant' && type === 'Restaurant/Cafe/Canteen') ||
          placeType === 'both') {
        const place = {
          id: values[headers.indexOf('LocalAuthorityCode')],
          name: values[headers.indexOf('business_name')],
          address: values[headers.indexOf('address')],
          type: type,
          rating: parseInt(values[headers.indexOf('rating')]),
          latitude: parseFloat(values[headers.indexOf('latitude')]),
          longitude: parseFloat(values[headers.indexOf('longitude')]),
        };
        
        if (place.latitude && place.longitude) {
          place.distance = getDistanceFromLatLonInKm(lat, lon, place.latitude, place.longitude);
          places.push(place);
        }
      }
    }

    // Sort places by distance
    places.sort((a, b) => a.distance - b.distance);

    return places;
  } catch (error) {
    if (error.name === 'AbortError') {
      console.log('===> Fetch aborted');
      return [];
    }
    console.log('===> ERROR: Error fetching places:', error);
    return [];
  }
};

export function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
  const R = 6371; // Radius of the Earth in km
  const dLat = deg2rad(lat2 - lat1);
  const dLon = deg2rad(lon2 - lon1);
  const a = 
    Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
    Math.sin(dLon/2) * Math.sin(dLon/2); 
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
  const d = R * c; // Distance in km
  return d;
}

export function deg2rad(deg) {
  return deg * (Math.PI/180);
}