import React, {useEffect} from 'react';
import landingSvg from '../images/landing.svg';

function Loading({ onCancel }) {
  useEffect(() => {
    console.log('===> Loading component mounted. onCancel type:', typeof onCancel);
  }, [onCancel]);

  return (
    <div className="text-center text-dark-blue">
      <img src={landingSvg} alt="Loading illustration" className="w-64 h-64 mx-auto mb-8" />
      <h2 className="text-2xl mb-4">Finding the best location</h2>
      <p className="mb-4">Loading...</p>
      <button 
        onClick={() => {
          console.log('===> Cancel button clicked in Loading component');
          if (typeof onCancel === 'function') {
            console.log('===> Calling onCancel function');
            onCancel();
          } else {
            console.error('onCancel is not a function:', typeof onCancel);
          }
        }}
        className="w-full bg-dark-orange text-pale-yellow py-3 px-4 rounded-full hover:bg-gray-200 transition duration-300"
      >
        Cancel
      </button>
    </div>
  );
}

export default Loading;