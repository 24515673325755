import React, { useRef, useState, useCallback } from 'react';
import ReactGA from 'react-ga4';
import { useNavigate } from 'react-router-dom';
import landingSvg from '../images/landing.svg';
import { findOptimalMeetingPoint } from './Helper';
import LocationInput from './LocationInput';
import Loading from './Loading';

function Home() {
    const [locations, setLocations] = useState([]);
    const [errorMessage, setErrorMessage] = useState(null);
    const [calculationPreference, setCalculationPreference] = useState('location');
    const [placeType, setPlaceType] = useState('both');
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const abortControllerRef = useRef(null);

    const handleAddLocation = (location) => {
      ReactGA.event({
        category: 'user_interaction',
        action: 'add_location',
        label: location.name,
      });
      if (locations.length < 5) {
        setLocations([...locations, { ...location, accessibilityMode: false }]);
      }
    };
  
    const handleRemoveLocation = (index) => {
      ReactGA.event({
        category: 'user_interaction',
        action: 'remove_location',
        label: locations[index].name,
      });
      setLocations(locations.filter((_, i) => i !== index));
    };
  
    const handleCancel = useCallback(() => {
      console.log('===> handleCancel called in Home component');
      if (abortControllerRef.current) {
          console.log('===> Aborting search...');
          abortControllerRef.current.abort();
          abortControllerRef.current = null; // Clear the reference after aborting
      } else {
          console.log('===> No ongoing search to abort');
      }
      setIsLoading(false);
      navigate('/', { state: { locations, calculationPreference, placeType } });
  }, [calculationPreference, navigate, placeType, locations]);

    const handleFindPlace = async () => {
        // Convert placeType array to 'both' if both options are selected
        const effectivePlaceType = Array.isArray(placeType) && placeType.length === 2 ? 'both' : placeType;

        ReactGA.event({
            category: 'engagement',
            action: 'find_place',
            label: `Find ${effectivePlaceType} button clicked`,
            value: 1
        });

        if (locations.length < 2) {
            alert("Please add at least two locations.");
            return;
        }

        console.log('===> handleFindPlace called with placeType:', effectivePlaceType);
        const searchParams = new URLSearchParams();
        locations.forEach((loc, index) => {
            searchParams.append(`location${index + 1}`, `${loc.name}|${loc.lat}|${loc.lon}`);
        });
        searchParams.append('preference', calculationPreference);
        searchParams.append('placeType', Array.isArray(effectivePlaceType) ? effectivePlaceType.join(',') : effectivePlaceType);

        navigate(`/loading?${searchParams.toString()}`);

        setIsLoading(true);
        try {
            const optimalPoint = await findOptimalMeetingPoint(
                locations, 
                calculationPreference
            );

            searchParams.append('station', optimalPoint.name);
            searchParams.append('stationLat', optimalPoint.lat);
            searchParams.append('stationLon', optimalPoint.lon);

            navigate(`/results?${searchParams.toString()}`);

        } catch (error) {
            if (error.name === 'AbortError') {
                console.log('===> Search aborted by user');
            } else {
                console.error('===> Error in handleFindPlace:', error);
                setErrorMessage(`Error finding ${placeType}: ${error.message}`);
                ReactGA.event({
                    category: 'result',
                    action: 'find_place_error',
                    label: error.message,
                    value: 1
                });
            }
            setIsLoading(false);
            navigate('/');
        }
    };

    const handlePlaceTypeChange = (type) => {
        console.log('===> handlePlaceTypeChange called with type:', type);
        
        setPlaceType((prev) => {
            if (prev === type) {
                return [];
            } else if (Array.isArray(prev)) {
                if (prev.includes(type)) {
                    return prev.filter(item => item !== type);
                } else {
                    return ['restaurant', 'pub'];
                }
            } else {
                return ['restaurant', 'pub'];
            }
        });
    };    

  return (
        <div className="max-w-md mx-auto">
            <img src={landingSvg} alt="Landing illustration" className="w-40 h-40 mx-auto mb-6" />
            <h2 className="text-xl mb-4 text-center font-medium text-dark-blue">Find a location</h2>
            <p className="text-sm text-center mb-6 text-dark-blue">Enter 2-5 starting locations to find the perfect central meeting point in London.</p>
            
            <LocationInput 
                onAddLocation={handleAddLocation} 
                onRemoveLocation={handleRemoveLocation}
                locations={locations}
                maxLocations={5}
            />
            
            <div className="w-full mb-4" style={{ height: '1px', backgroundColor: '#D9D9D9' }} />
            <div className="mb-4 text-dark-blue">
                <label className="block text-sm font-medium mb-2">Give me results by</label>
                <div className="flex space-x-2">
                    <button
                        onClick={() => setCalculationPreference('location')}
                        className={`py-2 px-4 text-sm rounded-full transition-colors duration-200 flex items-center justify-center space-x-2 ${
                            calculationPreference === 'location'
                                ? 'bg-white border border-dark-blue'
                                : 'bg-white border border-dark-blue hover:bg-gray-100'
                        }`}
                    >
                        <span className={`w-4 h-4 rounded-full border ${
                            calculationPreference === 'location'
                                ? 'bg-dark-orange border-dark-orange'
                                : 'border-gray-400'
                        }`}></span>
                        <span>Location</span>
                    </button>
                    <button
                        onClick={() => setCalculationPreference('time')}
                        className={`py-2 px-4 text-sm rounded-full transition-colors duration-200 flex items-center justify-center space-x-2 ${
                            calculationPreference === 'time'
                                ? 'bg-white border border-dark-blue'
                                : 'bg-white border border-dark-blue hover:bg-gray-100'
                        }`}
                    >
                        <span className={`w-4 h-4 rounded-full border ${
                            calculationPreference === 'time'
                                ? 'bg-dark-orange border-dark-orange'
                                : 'border-gray-400'
                        }`}></span>
                        <span>Travel time</span>
                    </button>
                </div>
            </div>
            
            <div className="w-full mb-4" style={{ height: '1px', backgroundColor: '#D9D9D9' }} />
            <div className="mb-6 text-dark-blue">
                <label className="block text-sm font-medium mb-2">Show me</label>
                <div className="relative p-4 bg-white border border-dark-blue rounded-xl space-y-3">
                    <div className="flex items-center space-x-3">
                        <input
                            id="restaurants"
                            type="checkbox"
                            className="w-5 h-5 border-2 border-dark-orange text-dark-orange focus:ring-0 rounded-md 
                                        checked:bg-dark-orange checked:border-dark-orange"
                            checked={placeType === 'restaurant' || (Array.isArray(placeType) && placeType.includes('restaurant'))}
                            onChange={() => handlePlaceTypeChange("restaurant")}
                        />
                        <label htmlFor="restaurants" className="text-dark-blue font-medium text-sm">Restaurants</label>
                    </div>
                    <div className="flex items-center space-x-3">
                        <input 
                            id="pubs" 
                            type="checkbox" 
                            className="w-5 h-5 border-2 border-dark-orange text-dark-orange focus:ring-0 rounded-md 
                                        checked:bg-dark-orange checked:border-dark-orange"
                            checked={placeType === 'pub' || (Array.isArray(placeType) && placeType.includes('pub'))}
                            onChange={() => handlePlaceTypeChange("pub")}
                        />
                        <label htmlFor="pubs" className="text-dark-blue font-medium text-sm">Pubs</label>
                    </div>
                </div>
            </div>

            
            {errorMessage && <p className="text-red-500 mb-4">{errorMessage}</p>}
            
            {isLoading ? (
                console.log('===> Rendering Loading component, isLoading:', isLoading),
                <Loading onCancel={handleCancel} />
            ) : (
                <button
                    onClick={handleFindPlace}
                    className={`w-full bg-dark-orange text-pale-yellow py-3 px-4 rounded-full text-sm font-medium ${locations.length < 2 ? 'opacity-50 cursor-not-allowed' : ''}`}
                    disabled={locations.length < 2}
                >
                    Find a location
                </button>
            )}
        </div>
  );
}

export default Home;


